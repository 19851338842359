import React, { useState, useCallback} from 'react';

function AddToVaultSection({ isDarkMode, handleFileUpload, handleSiteUpload, handleCustomDataSave, isMobile, styles }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [customData, setCustomData] = useState('');
  const [siteUrl, setSiteUrl] = useState('');

  const handleSave = useCallback(async () => {
    await handleCustomDataSave(customData);
    setCustomData(''); // Clear the textarea after saving
  }, [customData, handleCustomDataSave]);

  return (
    <div>
      <button 
        className='link-button' 
        style={{ 
          fontSize: "1rem", 
          marginLeft: "2px", 
          backgroundColor: "transparent", 
          border: "none", 
          textDecoration: "underline", 
          cursor: "pointer" 
        }} 
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? 'Hide Add to Vault' : 'Add to Vault'}
      </button>
      
      {isExpanded && (
        <div style={{ marginTop: '10px' }}>
          <textarea
            placeholder="Type or paste something you want to add to the database..."
            rows={isMobile ? 10 : 5}
            style={{ ...styles.textarea, marginBottom: '2px', width: '100%', paddingLeft: '5px' }}
            value={customData}
            onChange={(e) => setCustomData(e.target.value)}
          />
          <button style={{...styles.button, marginTop: '0px'}} onClick={() => handleSave(customData)}>Save to database</button>
          
          <div style={{ marginTop: '15px'}}>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
            <button
              className="link-button"
              style={{ 
                fontSize: "1rem", 
                backgroundColor: "transparent", 
                border: "none", 
                textDecoration: "underline", 
                cursor: "pointer", 
                marginLeft: '2px' 
              }}
              onClick={(e) => { e.preventDefault(); document.getElementById('fileInput').click(); }}
            >
              Upload File
            </button>
          </div>
          
          <div style={{ marginTop: '30px', marginBottom: '30px' }}>
            <input
              className={isDarkMode ? 'sleek-input-dark' : 'sleek-input-light'}
              type="text"
              value={siteUrl}
              onChange={(e) => setSiteUrl(e.target.value)}
              placeholder="Enter website URL"
              style={{ marginRight: '10px', marginLeft: '0px' }}
            />
            <button className={isDarkMode ? 'btn-dark' : 'btn-light'}
                    onClick={() => {
                      handleSiteUpload(siteUrl); // Perform the action
                      setSiteUrl(''); // Clear the input after submission
                    }}
            >
              Save URL Data
            </button>
          </div>
          <div style={{
            borderBottom: `1px solid ${isDarkMode ? '#626C80' : '#ccc'}`,
            marginTop: '30px',
            marginBottom: '20px',
            width: '100%'
          }} />
        </div>
      )}
    </div>
  );
}

export default AddToVaultSection;